@font-face {
    font-family: "OpenSans-Regular";
    src: local("OpenSans-Regular"), url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "OpenSans-Bold";
    src: local("OpenSans-Bold"), url(./assets/fonts/OpenSans-Bold.ttf) format("truetype");
}
@font-face {
    font-family: "Inter-Regular";
    src: local("Inter-Regular"), url(./assets/fonts/Inter-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Inter-Bold";
    src: local("Inter-Bold"), url(./assets/fonts/Inter-Bold.ttf) format("truetype");
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    background-color: #F6F8F9;
}

a {
    text-decoration: none;
}

.margin-top-1x {
    margin-top: 15px;
}

.margin-top-2x {
    margin-top: 30px;
}

.margin-top-10px {
    margin-top: 10px;
}

.margin-top-20px {
    margin-top: 20px;
}

.margin-top-25px {
    margin-top: 25px;
}

.margin-top-40px {
    margin-top: 40px;
}

.margin-right-20px {
    margin-right: 20px;
}

.dashboard-container {
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 109px);
}

.dashboard-sidebar-container {
    padding: 16px;
    display: block;
}

.dashboard-main-container {
    width: 100%;
    display: block;
    padding: 16px;
    max-height: calc(100vh - 60px);
    overflow-y: scroll;
}

.admin-col-2 {
    width: 128px;
}

.admin-col-3 {
    width: 128px;
}

.admin-col-4 {
    width: 144px;
}

.admin-col-5 {
    width: 230px;
}

@media only screen and (max-width: 1160px) {
    .dashboard-sidebar-container {
        display: none;
    }
}

@media only screen and (max-width: 930px) {
    .admin-col-5 {
        display: none;
    }
}

@media only screen and (max-width: 730px) {
    .admin-col-2 {
        display: none;
    }
}

@media only screen and (max-width: 660px) {
    .dashboard-main-container {
        padding: 0;
    }
}

@media only screen and (max-width: 580px) {
    .admin-col-3 {
        display: none;
    }
}